<template>
<div>
  <div class="flex justify-between py-2">
    <a-button
        type="primary"
        class="default-button mr-1 base_text flex justify-center items-center"
        size="small"
        @click="add_organization_request">
      <a-icon type="plus" style="font-size: large; margin-right: 0px; width: 20px; height: 20px" />
      <div class="ml-2">
        {{ $t('add_organization_request') }}
      </div>
    </a-button>
  </div>
  <card>
    <AgGridTable
        ref="refAgGridTableCreateRequest"
        updated_event_bus="updated_organizations"
        :column_defs="column_defs_create_org"
        :end_point="'organizations/organizations/'"
        name_event_bus="organizations_api_event_bus"/>
  </card>
  <draw-create-org ref="refDrawCreateOrg" :title="$t('add_organization_request')" @after_create="after_update_organization"/>
  <organization-show-drawer ref="refOrganizationShowDrawer" @after_update_organization="after_update_organization"/>
</div>
</template>

<script>
import DrawCreateOrg from "./DrawCreateOrg";
import AgGridTable from "@/components/Forms/FormAddClient/AgGridTable";
import formOrg from "@/pages/OrganizationRequest/Mixins/formOrg";
import Card from "@/crm_components/card/Card";
import OrganizationShowDrawer from "./Organization/OrganizationShowDrawer";
import formSaveMixin from "@/crm_components/form_save/FormSaveMixin";
export default {
  name: "OrganizationRequest",
  components: {OrganizationShowDrawer, DrawCreateOrg, AgGridTable, Card},
  mixins:[
    formOrg,
    formSaveMixin
  ],
  methods:{
    add_organization_request(){
      this.$refs.refDrawCreateOrg.visible = true
    },
    open_on_edit_org(data, edit = false){
      console.log('open_on_edit_org', data)
      this.$refs.refOrganizationShowDrawer.data = data
      if (edit){
        this.$refs.refOrganizationShowDrawer.edit()
      }
    },
    after_update_organization(data){
      this.$refs.refAgGridTableCreateRequest.updateMessage(data.data)
    },
    delete_method_organization(name_event_bus, end_point, data){
      this.$confirm({
        title: this.$t('are_you_sure_delete'),
        okText: this.$t('yes'),
        okType: 'danger',
        cancelText: this.$t('no'),
        onOk: () => {
          this.handleSubmit(null, name_event_bus+"_delete", end_point, 'delete', data)
          this.$emit('close')
        },
        onCancel() {
          console.log('Cancel');
        },
      });

    }

  }
}
</script>

<style scoped>

</style>
