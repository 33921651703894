<script>
import AgGridTableNumber from "@/components/Forms/FormAddClient/AgGridTableNumber";
import Actions from "../Actions";
export default {
  name: "formOrg",
  components:{
    AgGridTableNumber,
    Actions
  },
  data(){
    return{
      formItemsCreateOrg: [
        {
          model: 'name',
          show_label: true,
          validate: 'required',
        },
        {
          model: 'bin',
          show_label: true,
          validate: 'required|bin_validation',
        },
        {
          model: 'region_fk',
          type:'select',
          end_point:'organizations/regions/',
          validate: 'required',
        },
        {
          model: 'number',
          show_label: true,
          validate: 'required',
          placeholder: '+7',
          mask:'+7##########'
        },
        {
          model:'add_phone',
          type:'button-add',
          show_label: false,
          sub_items:[],
          add_items:[
            {
              model: 'number',
              label: this.$t('number'),
              show_label: true,
              validate: 'required',
              placeholder: '+7',
              mask:'+7##########'
            }
          ]
        }
      ],
      column_defs_create_org:[
        {
          headerName: "",
          field: "name",
          cellRendererFramework: 'Actions',
          minWidth: 75,
          maxWidth: 75,
          cellRendererParams: {
            onEditClicked: params => {
              this.open_on_edit_org({data:params.data}, true)
            },
            onDeleteClicked: params => {
              this.delete_method_organization('organizations_api_event_bus', 'organizations/organizations/', params.data)
            },

          }
        },
        {
          headerName: "№",
          field: "full_name",
          minWidth: 100,
          maxWidth: 100,
          cellRendererFramework: 'AgGridTableNumber',
        },
        {
          headerName: this.$t('name'),
          field: 'name',
          sortable: true,
          filter: true,
          width: 200,
        },
        {
          headerName: this.$t('number'),
          field: 'phone_numbers_name',
          sortable: true,
          filter: true,
          width: 200,
        },
        {
          headerName: this.$t('bin'),
          field: 'bin',
          sortable: true,
          filter: true,
          width: 200,
        },
        {
          headerName: this.$t('region'),
          field: 'region_name',
          sortable: true,
          filter: true,
          width: 200,
        },
      ]
    }
  }

}
</script>

<style scoped>

</style>
